<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseActivityVerify">
		<div class="baseActivityVerify-content">
			<div class="activityVerify-table">
				<el-table :data="tableData" border>
					<el-table-column  prop="id" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="名称">
					</el-table-column>
					<el-table-column width="240px" prop="createBy" label="发布人">
					</el-table-column>
					<el-table-column width="200px" prop="status" label="上传状态">
						<template slot-scope="scope">
							<span>{{scope.row.status == 0?"未上传":"已上传"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="deadline" label="截止时间">
						<template slot-scope="scope">
							<span>{{dateFormat(scope.row.deadline)}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('edit', scope)">编辑</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('detail', scope)">详情</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMaterials,
		deleteMaterials,
	} from "@/api/materials"
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
			};
		},
		mounted() {
			this.getMaterialsList()
		},
		methods: {
			getMaterialsList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				listMaterials(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getMaterialsList();
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/allMaterialsManage/detail?materialsId=${scope.row.id}`
						})
						break;
					case "edit":
						this.$router.push({
							path: `/allMaterialsManage/edit?materialsId=${scope.row.id}`
						})
						break;
					case "delete":
						this.$confirm('确定删除此材料?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'error'
						}).then(() => {
							deleteMaterials(scope.row.id, {
								id: scope.row.id
							}).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.pageIndex = 1;
									this.tableData = [];
									this.getMaterialsList();
								}
							})
						}).catch(() => {});
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.baseActivityVerify {
		.baseActivityVerify-content {
			padding: 20px 20px;
		}
	}
</style>
